.commit-container {
	width: 100%;
	margin: 2rem 0;
}

.commits-list-header-skeleton {
	margin-bottom: 1rem;
	height: 28px;
}

.commits-list-skeleton {
	height: 321px;
	margin-bottom: 1rem;

	@media screen and (max-width: 568px) {
		height: 465px;
	}

	@media screen and (max-width: 378px) {
		height: 563px;
	}
}

.commit-container {
	li {
		padding: 1rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		transition: var(--fast-transition);

		&:hover {
			background: var(--clr-white-light);
		}

		> div {
			display: flex;
			gap: 1rem;
			justify-content: space-between;

			@media screen and (max-width: 568px) {
				flex-direction: column;
				gap: 1rem;
			}

			> div:nth-of-type(1) {
				display: flex;
				gap: 0.5rem;
				align-items: center;

				@media screen and (max-width: 568px) {
					justify-content: space-between;
				}

				@media screen and (max-width: 378px) {
					flex-direction: column;
				}

				a {
					display: flex;

					img {
						width: 32px;
						height: 32px;
						border-radius: 50%;
					}
				}

				> div {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;

					span {
						padding-left: 0.25rem;
					}
				}
			}

			> div:nth-of-type(2) {
				display: flex;
				gap: 0.5rem;

				@media screen and (max-width: 568px) {
					> * {
						flex-basis: 100%;
						justify-content: center;
					}
				}
			}
		}
	}

	hr:not(:last-child) {
		width: calc(100% - 2rem);
		margin: 0 auto;
		border: none;
		border-top: dashed 1.6px var(--clr-white-dark);
		height: 1.6px;
	}

	> div {
		> div {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;

			> span {
				width: 4px;
				height: 1.5rem;
				background: var(--clr-secondary);
				margin-left: calc(28px / 2 + 1rem - (4px / 2));
			}

			> div {
				display: flex;
				gap: 1rem;
				align-items: center;
				padding-left: 1rem;
			}
		}
	}

	h2,
	h3 {
		font-size: 1.25rem;
		line-height: 28px;
		margin-bottom: 0;
	}

	.commit-msg-header a {
		line-height: 24px;

		width: fit-content;
		text-decoration: none;
		color: var(--clr-black);

		&:hover {
			text-decoration: underline !important;
		}

		&:active {
			text-decoration: dashed underline !important;
		}
	}

	ul {
		list-style-type: none;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
}

a.commit-author {
	text-decoration: none !important;
	color: var(--clr-black);

	&:hover {
		text-decoration: underline !important;
	}

	&:active {
		text-decoration: dashed underline !important;
	}
}

@media screen and (max-width: 500px) {
	.commit-container {
		margin: 1.5rem 0;
	}
}
