.hero-container {
	display: flex;
	min-height: calc(100svh);
	padding-top: 56px;

	.secondary-avatar {
		--avatar-size: 256px;
		display: none;
		height: var(--avatar-size);
		width: var(--avatar-size);
		overflow: hidden;
		border-radius: 50%;
		margin: 0 auto 1rem;
		border: 3.2px solid #1b1b1b;

		@media screen and (max-width: 1040px) {
			display: block;

			> img {
				background-color: var(--clr-body);
				height: var(--avatar-size);
				width: var(--avatar-size);
				object-fit: cover;
				object-position: -4px 32px;
				transform: scale(1.5);
			}
		}

		@media screen and (max-width: 560px) {
			--avatar-size: 192px;

			> img {
				object-position: -4px 16px;
				transform: scale(1.5);
			}
		}
	}

	.greet-buttons {
		display: flex;
		flex-wrap: wrap;
		gap: 0.75rem;

		.btn {
			font-size: 1.25rem;
			min-height: 41.6px;
		}
	}

	h1 {
		margin: 0;
		margin-bottom: 1rem;
		display: flex;
		flex-direction: column;
		font-size: 3rem;
		line-height: 56px;
		animation: fade-greet-text 0.5s ease-in forwards;
		opacity: 0;
		z-index: 0;
		letter-spacing: -0.035em;
		text-wrap: balance;

		@media screen and (max-width: 800px) {
			text-align: center;
		}

		@media screen and (max-width: 560px) {
			font-size: 2.25rem;
			line-height: 2.5rem;
			letter-spacing: -0.025em;
		}
	}

	> div {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 1rem;
		padding: 2rem;
		align-items: center;
		background: var(--clr-white);

		.greet-card {
			display: flex !important;
			flex-direction: column;
			gap: 1rem;

			> div:first-child {
				padding: 1rem;
				position: relative;
				max-width: 720px;

				p {
					font-size: 1.25rem;
					margin-bottom: 1.25rem;

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}

		@media screen and (max-width: 1040px) {
			padding: 3rem 2rem;
			justify-content: flex-start;
		}

		@media screen and (max-width: 560px) {
			padding: 2rem 1.5rem;
			justify-content: flex-start;
		}
	}

	> img {
		max-width: 720px;
		min-width: 0;
		flex: 0 1 auto;
		object-fit: cover;
		max-height: calc(100svh - 56px);
		min-height: 640px;

		@media screen and (max-width: 1040px) {
			display: none;
		}
	}

	.greet-card-stars {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		margin-top: 1rem;

		.ellipsis {
			height: 3rem;
			width: 3rem;
			border-radius: 50%;
			background-color: var(--clr-white);
			display: flex;
			justify-content: center;
			align-items: center;
			border: 2px solid #1b1b1b;
			margin-left: -0.5rem;
			z-index: 20;
		}

		.stargazer {
			height: 3rem;
			width: 3rem;
			border-radius: 50%;
			border: 2px solid #1b1b1b;
			background-color: var(--clr-white);
			margin-left: -0.5rem;

			&:first-of-type {
				margin-left: 0;
			}
		}

		.skeleton {
			width: 224px;
			height: 32px;
		}

		.stargazer-skeleton {
			height: 3rem;
			width: 3rem;
			border-radius: 50%;
			animation: skeleton-loading 1s linear alternate infinite;
			margin-left: -0.5rem;

			&:first-of-type {
				margin-left: 0;
			}
		}

		> div {
			display: flex;
			flex-wrap: wrap;
		}

		h2 {
			font-size: 1.25rem;
			line-height: 26px;
			font-weight: 600;
			margin: 0;
		}
	}

	@media screen and (max-width: 1040px) {
		grid-template-columns: 1fr;
		gap: 1rem;
		height: auto;
	}
}
