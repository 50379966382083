.to-top {
	cursor: pointer;
	position: fixed;
	bottom: 0.7rem;
	right: 1.25rem;
	height: 2.6rem;
	width: 2.6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--clr-white);
	color: var(--clr-black);
	font-size: 1.5rem;
	text-decoration: none;
	border-radius: 50%;
	border: solid 2px var(--clr-black);
	transition: var(--slow-transition);
	opacity: 0;
	pointer-events: none;
	z-index: 10;
}

.to-top:hover,
.to-top:focus-visible {
	color: var(--clr-white);
	background: var(--clr-secondary);
	outline: solid 2px var(--clr-white);
}

.to-top.active {
	bottom: 1.15rem;
	opacity: 1;
	pointer-events: auto;
	animation: pulse 2s infinite;
	animation-delay: 1s;
}

@media screen and (max-width: 500px) {
	.to-top {
		bottom: 0.5rem;
		right: 1rem;
		height: 2.2rem;
		width: 2.2rem;
		font-size: 1.4rem;
	}

	.to-top:hover {
		color: var(--clr-black);
		background: var(--clr-white);
	}

	.to-top:active {
		color: var(--clr-white);
		background: var(--clr-secondary);
	}

	.to-top.active {
		bottom: 0.95rem;
		opacity: 1;
		pointer-events: auto;
		animation: pulse 2s infinite;
		animation-delay: 1s;
	}
}
